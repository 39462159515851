(() => {
  'use strict';

  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1245;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu');
  window.$MENU_CHECKBOX = $('#header-menu-checkbox');


  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.outerHeight();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;


  // Variables
  // ---------
  let slickSettings = {
    elementAsContext: true,
    slider: '> div:first-child',
    appendArrows: '.slick-arrows',
    appendDots: '.slick-dots',
    dotsClass: '',
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 300,
    centerMode: false,
    centerPadding: '0px',
    cssEase: 'ease-in-out',
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    pauseOnDotsHover: true,
    customPaging: () => '<div></div>',
    draggable: false,
    swipeToSlide: true,
    touchThreshold: 100,
  };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '\\+7 \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__'
    },
    'number-mask': {
      //alias: 'numeric',
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      //alias: 'numeric',
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });
  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');


  $WINDOW
    .one('load', () => {
      $WINDOW.trigger('calcSizes');
      $DOCUMENT
        .trigger('headerMenuEvents')
        .trigger('initSpinner');
    })
    .on('resize', () => {
      $DOCUMENT.trigger('headerMenuEvents');
    });


  $BODY
    .on('yiiListViewUpdating', () => {
      $.mouseLoader(true);
    })
    .on('yiiListViewUpdated', () => {
      $DOCUMENT.trigger('initSpinner');

      $.mouseLoader(false);
    });


  $DOCUMENT
    .ready(() => {
      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', e => {
          e.currentTarget.checked ? $BODY.addClass('menu-is-opened') : $BODY.removeClass('menu-is-opened');
        })
        .prop({'checked': false, 'disabled': false})
        .trigger('change');


      // Main rotator
      $('.main-rotator-container > div').slick($.extend({}, slickSettings, {
        slide: '.main-rotator-slide',
      }));

      // Footer partners slider
      // ----------------------
      $('.footer-partners-slider').slick($.extend({}, slickSettings, {
        appendDots: false,
        slide: '.footer-partner-slide',
        slidesToScroll: 1,
        slidesToShow: 8,
        responsive: [
          {
            breakpoint: DESKTOP_WIDTH,
            settings: {slidesToShow: 7}
          },
          {
            breakpoint: PORTRAIT_TABLET_WIDTH,
            settings: {slidesToShow: 5}
          },
          {
            breakpoint: 600,
            settings: {slidesToShow: 4}
          },
          {
            breakpoint: PORTRAIT_MOBILE_WIDTH,
            settings: {slidesToShow: 3}
          },
          {
            breakpoint: SMALL_MOBILE_WIDTH,
            settings: {slidesToShow: 2}
          }
        ]
      }));
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if (IS_DESKTOP_WIDTH()) {
        $('#structure').css('padding-top', 0);

        if (!$HEADER_MENU.hasClass('is-desktop-events')) {
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $HEADER_MENU
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .css('top', '')

            // Header menu catalog item layout
            .find('> ul > .catalog-item > ul')
              .isotope({
                layoutMode: 'masonry',
                percentPosition: true,
                transitionDuration: 0,
                containerStyle: null
              });
        }
      }
      else {
        if (!$HEADER.hasClass('is-mobile-events')) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events')

            .css('top', HEADER_HEIGHT)

            // Header menu catalog item layout
            .find('> ul > .catalog-item > ul').isotope('destroy');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if (!($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu').length)) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu div > a, .header-menu div > span, .header-menu .dropmarker', e => {
              e.preventDefault();

              let $self = $(e.currentTarget),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              $parent.siblings('.with-dropdown').removeClass('is-hovered').children('ul').slideUp(300);

              if (isWithDropdown) {
                if (isOnHover) {
                  if ($self.prop('tagName').toUpperCase() === 'A')
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').children('ul').slideUp(300);
                }
                else {
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              }
              else {
                if ($self.prop('tagName').toUpperCase() === 'A')
                  location.href = $self.prop('href');
              }
            });
        }

        $('#structure').css('padding-top', HEADER_HEIGHT);
      }
    })

    // Init spinner
    // ------------
    .on('initSpinner basketListUpdated', () => {
      $.widget('ui.customSpinner', $.ui.spinner, {
        _enhance: function() {
          this.uiSpinner = this.element
            .attr('autocomplete', 'off')
            .wrap(this._uiSpinnerHtml())
            .parent().parent()

            // Add buttons
            .append(this._buttonHtml());
        },
        _uiSpinnerHtml: ()=> '<div>',
        _buttonHtml: () => '<div class="ui-spinner-button ui-spinner-up"><span>+</span></div>'.concat(
          '<div class="ui-spinner-button ui-spinner-down"><span>&ndash;</span></div>'),
      });

      $('.js-spinner-input')
        .each((index, element) => {
          let $spinner = $(element),
              min = $spinner.data('min') || 1,
              max = $spinner.data('max') || null,
              step = $spinner.data('step') || 1;

          $spinner
            .val((index, value) => value < min ? min : value)
            .customSpinner({
              culture: 'en',
              min: min,
              max: max,
              page: 0,
              step: step,

              change: e => {
                e.preventDefault();

                let _min = $(e.target).data('min') || 1;

                if ($(e.target).val() < _min)
                  $(e.target).val(_min).trigger('change');
              },
              spin: (e, ui) => {
                e.preventDefault();

                $(e.target).val(ui.value).trigger('change');
              }
            });
        })
        .off('cut copy paste')
        .on('cut copy paste', e => {
          e.preventDefault();
        });
    })

    // Show header search popup
    // ------------------------
    .on('click.showHeaderHearch', '.header-search-button > span', e => {
      e.preventDefault();

      $BODY.addClass('search-is-opened');
      $('.search-form-container > form input').focus();
    })

    // Hide header search popup
    // ------------------------
    .on('click.hideHeaderSearch', e => {
      if (!$(e.target).closest('.header-search-button > span').length && ($(e.target).closest('.search-form-container .button-close-container span').length || !$(e.target).closest('.search-form-container > form').length)) {
        $BODY.removeClass('search-is-opened');

        e.stopPropagation();
      }
    })

    // Scroll to
    // ---------
    .on('scrollTo', (e, $element, speed, offset) => {
      if ($element === undefined)
        $element = $(e.target);

      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 150;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset) - (IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT)});
    })
    .on('click.jsScrollTo', '.js-scroll-to', e => {
      e.preventDefault();

      let $link = $(e.currentTarget),
          $element = $($link.attr('href').substring($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs .tabs-nav li a', e => {
      e.preventDefault();

      let $lnk = $(e.currentTarget).parent();

      if (!$lnk.hasClass('active')) {
        let $parent = $lnk.parents('.js-tabs').addClass('initialized'),
            $destination = !!$parent.data('tabsDest') ? $($parent.data('tabsDest')) : $parent.next();

        $lnk.addClass('active').siblings().removeClass('active');
        $destination.children().eq($lnk.index()).addClass('active').siblings().removeClass('active');
      }
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: (widget, current) => {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: (widget, current) => {
      if (current.contentType == 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH())
        current.$content.find('.js-autofocus-inp').trigger('focus');
    }
  });

  $.fancyBoxConfirm = opts => {
    opts = $.extend(true, {
      title: 'Вы уверены?',
      message: '',
      buttons: {
        yes: {
          label: 'Да',
          cssClass: 'active'
        },
        no: {
          label: 'Нет',
          cssClass: ''
        }
      },
      callbacks: {
        yes: $.noop,
        no: $.noop
      }
    }, opts || {});

    let content = '<div class="form-popup-container fancybox-confirm">'.concat(
      '<div class="form-block-container">',
      '<div class="subsubheader">', opts.title, '</div>',
      opts.message,
      '<div class="form">',
      '<div class="form-buttons">');

    $.each(opts.buttons, (key, button) => {
      if (button !== false)
        content = content.concat('<div class="form-button">',
          '<button class="button-style ', button.cssClass, '" data-fancybox-close data-value="', key, '"><span>', button.label, '</span></button>',
          '</div>');
    });

    content = content.concat('</div></div></div></div>');

    $.fancybox.open({
      type: 'html',
      src: content,
      opts: {
        modal: true,
        baseTpl:
          '<div class="fancybox-container" role="dialog" tabindex="-1">'.concat(
          '<div class="fancybox-bg"></div>',
          '<div class="fancybox-inner">',
          '<div class="fancybox-stage"></div>',
          '</div>',
          '</div>'),
        afterClose: (widget, currentSlide, e) => {
          let button = e ? $(e.target).closest('button') || $(e.currentTarget).closest('button') : null,
              value = button ? $(button).data('value') : 'no';

          opts.callbacks[value](widget, currentSlide);
        }
      }
    });
  };
})();